.chatMsg {
	position: relative;

	&:before {
		width: 20px;
	}

	&:after {
		width: 26px;
		background-color: white;
		/* All tails have the same bg cutout */
	}

	&:before,
	&:after {
		position: absolute;
		bottom: 0;
		height: 25px;
		/* height of our bubble "tail" - should match the border-radius above */
		content: '';
	}
}

.chatMsg:not(.other) {
	&:before {
		right: -7px;
		border-bottom-left-radius: 16px 14px;
	}

	&:after {
		right: -26px;
		border-bottom-left-radius: 10px;
	}
}

.chatMsg.other {
	&:before {
		left: -7px;
		border-bottom-right-radius: 16px 14px;
	}

	&:after {
		left: -26px;
		border-bottom-right-radius: 10px;
	}
}

.chatMsg a
{
	color: inherit;
}